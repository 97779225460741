import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-8" }
const _hoisted_2 = { class: "flex items-center justify-between py-4" }
const _hoisted_3 = { class: "flex items-center gap-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "flex items-center gap-2" }
const _hoisted_6 = { class: "box py-5 relative" }
const _hoisted_7 = { class: "flex items-center space-x-2" }
const _hoisted_8 = ["onClick"]

import { computed, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import { STATUS_TENANT } from "@ems/constants/account_registration_status";
import { ITenantParameters } from "@/models/Tenant";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import { formatDateTime } from "@/utils/helpers/convertDateTime";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const filterOptions = [
  "All",
  STATUS_TENANT[STATUS_TENANT.Pending],
  STATUS_TENANT[STATUS_TENANT.Approved],
  STATUS_TENANT[STATUS_TENANT.Rejected],
];

const disabled = ref(false);
const small = ref(false);
const currentPage = ref(1);
const pageSize = ref(10);
const activeFilter = ref("All");
const searchText = ref("");

const router = useRouter();
const route = useRoute();
const tenantAccountList = computed(
  () => TenantUserModule.dataGetListTenantGetter
);

const paginationGet = computed(() => TenantUserModule.paginationGetter);

const isLoadingDataListTenant = computed(
  () => TenantUserModule.isLoadingDataListTenantGetter
);

const handleFilterStatus = (filter: string) => {
  activeFilter.value = filter;
  getTenantAccount({
    Status: STATUS_TENANT[filter as keyof typeof STATUS_TENANT],
  });
};

const handleSizeChange = (val: number) => {
  currentPage.value = 1;
};
const handleCurrentChange = (val: number) => {
  // do nothing
};

const handleDetailAccountProfile = (_: number, tenantId: string) => {
  router.push(`${route.path}/${tenantId}/details`);
};

const handleSearchCompany = () => {
  getTenantAccount({
    CompanyName: searchText.value,
  });
};

const getTenantAccount = (params?: ITenantParameters) => {
  TenantUserModule.getListTenantAction({
    ...params,
    CurrentPage: currentPage.value,
    Limit: pageSize.value,
  });
};

onMounted(() => {
  getTenantAccount();
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(filterOptions, (opt) => {
            return _createElementVNode("button", {
              class: _normalizeClass(["px-8 py-2 transition-colors", [
              'filter-btn',
              { 'filter-btn-active': activeFilter.value === opt },
            ]]),
              key: opt,
              onClick: _withModifiers(($event: any) => (handleFilterStatus(opt)), ["prevent"])
            }, _toDisplayString(opt), 11, _hoisted_4)
          }), 64))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_input, {
            modelValue: searchText.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event)),
            placeholder: "Search...",
            clearable: "",
            class: "w-72 custom-input"
          }, {
            prefix: _withCtx(() => [
              _createVNode(BaseSvgIcon, {
                class: "hover:text-blue-400 w-5 h-full text-cafe-gray",
                name: "search"
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("button", {
            class: "btn btn--green-primary px-6 py-2",
            onClick: _withModifiers(handleSearchCompany, ["prevent"])
          }, " Search ")
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mb-3 flex justify-between justify-items-center gap-4" }, [
          _createElementVNode("div", { class: "flex space-x-8" }, [
            _createElementVNode("h1", { class: "text-left text-lg font-semibold whitespace-nowrap" }, " Account Registrations ")
          ])
        ], -1)),
        _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          class: "w-full table-custom",
          style: { borderRadius: '4px' },
          data: tenantAccountList.value,
          height: "66vh",
          stripe: "",
          "cell-class-name": "text-cafe-dark",
          "header-row-class-name": "tableHeaderRow",
          "row-class-name": "tableRow",
          "element-loading-text": "Loading...",
          "element-loading-background": "rgba(0, 0, 0, 0.6)"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "TenantId",
              hidden: ""
            }),
            _createVNode(_component_el_table_column, {
              "min-width": 100,
              prop: "CompanyName",
              label: "Company Name",
              sortable: ""
            }),
            _createVNode(_component_el_table_column, {
              "min-width": 100,
              prop: "CompanyEnglishName",
              label: "Company's English Name",
              sortable: ""
            }),
            _createVNode(_component_el_table_column, {
              "min-width": 100,
              prop: "CompanyRegistrationNumber",
              sortable: "",
              label: "Registration Number"
            }),
            _createVNode(_component_el_table_column, {
              prop: "Country",
              sortable: "",
              label: "Country"
            }),
            _createVNode(_component_el_table_column, {
              prop: "Province",
              sortable: "",
              label: "Province"
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(scope.row.Province ? scope.row.Province : scope.row.Country), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "CreatedDate",
              sortable: "",
              label: "Created Date"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("p", null, _toDisplayString(_unref(formatDateTime)(scope.row.CreatedDate)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "Status",
              label: "Status"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("p", null, _toDisplayString(_unref(STATUS_TENANT)[scope.row.Status]), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "Action",
              label: "Details"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("button", {
                    type: "button",
                    onClick: _withModifiers(($event: any) => (
                    handleDetailAccountProfile(scope.$index, scope.row.TenantId)
                  ), ["prevent"])
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("p", { class: "underline" }, "View Details", -1)
                  ]), 8, _hoisted_8)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"])), [
          [_directive_loading, isLoadingDataListTenant.value]
        ]),
        _createVNode(_component_el_pagination, {
          currentPage: currentPage.value,
          "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((currentPage).value = $event)),
          "page-size": pageSize.value,
          "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((pageSize).value = $event)),
          "page-sizes": [10, 20, 30, 40],
          small: small.value,
          disabled: disabled.value,
          background: false,
          layout: "sizes, prev, pager, next",
          total: paginationGet.value.TotalRow,
          onSizeChange: handleSizeChange,
          onCurrentChange: handleCurrentChange
        }, null, 8, ["currentPage", "page-size", "small", "disabled", "total"])
      ])
    ])
  ]))
}
}

})